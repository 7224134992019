import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import {createUseStyles} from 'react-jss';

const useStyles = createUseStyles(() => ({
    btnIconTooltipShow: {
        display: props => props.display
    }
}));

const TooltipIconButton = (props) => {

    const [optionsTooltip, setOptionsTooltip] = React.useState({});
    const [optionsButton, setOptionsButton] = React.useState({});
    const myRef = React.createRef();

    React.useEffect(() => {
        if (props.options.optionsTooltip) setOptionsTooltip(props.options.optionsTooltip);
        if (props.options.optionsButton) setOptionsButton(props.options.optionsButton);
    }, [props]);

    const {disabled, hidden, ...config} =  optionsButton;

    const iconDisabled = disabled === true || props.disabled === true;
    const iconHidden = hidden === true || props.hidden === true;

    const style = {display: iconHidden === true ? 'none' : 'block'}
    const classes = useStyles(style);

    return (
        <span className={classes.btnIconTooltipShow}>
            <Tooltip ref={myRef} title={props.tooltip} placement="top" {...optionsTooltip} arrow>
                <span>
                    <IconButton
                      color="inherit"
                      onClick={(e) => props.onClick(optionsButton, e)}
                      {...config}
                      disabled={iconDisabled}
                      className={classes.btnIconTooltipShow}
                    >
                        {props.icon}
                    </IconButton>
                </span>
            </Tooltip>
        </span>
    )
}

TooltipIconButton.defaultProps = {
    tooltip: ''
    ,icon: null
    ,options: {}
    ,onClick: (options) => {}
}

TooltipIconButton.propTypes = {
    icon: PropTypes.object.isRequired
}

export default TooltipIconButton;
